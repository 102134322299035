<script setup lang="ts">
import { footerWidthFluid } from "@/layouts/config/helper"

const runtimeConfig = useRuntimeConfig()
const appName = runtimeConfig.public.APP_NAME

const currentYear = new Date().getFullYear()

</script>

<template>
    <!--begin::Footer-->
    <div
        id="kt_footer"
        class="footer py-4 d-flex flex-lg-column">
        <!--begin::Container-->
        <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-between"
            :class="{
                'container-fluid': footerWidthFluid,
                'container-xxl': !footerWidthFluid,
            }">
            <!--begin::Copyright-->
            <div class="text-gray-900 order-2 order-md-1">
                <span class="text-muted fw-semibold me-1">
                    {{ currentYear }} &copy;
                </span>
                <a
                    href="https://christtalker.com"
                    target="_blank"
                    class="text-gray-800 text-hover-primary">
                    {{ appName }}
                </a>
            </div>
            <!--end::Copyright-->

            <!--begin::Menu-->
            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li class="menu-item">
                    <a
                        href="https://podcasts.apple.com/us/podcast/christ-talker/id1251540399"
                        target="_blank"
                        class="menu-link px-2">
                        {{ $t(`podcastApple`) }}
                    </a>
                </li>

                <li class="menu-item">
                    <a
                        href="https://open.spotify.com/show/2xszQud9YzB7M94GFL4I5d?si=3f1f1980aa3a44a3"
                        target="_blank"
                        class="menu-link px-2">
                        {{ $t(`podcastSpotify`) }}
                    </a>
                </li>
            </ul>
            <!--end::Menu-->
        </div>
    <!--end::Container-->
    </div>
    <!--end::Footer-->
</template>
